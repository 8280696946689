<template>
  <div>
    <!-- dict: download45F0B8ED59F89D341C1E53771729B013.swf -->
    <!-- pic45082F704FF819C1662068850A7D6EBD.jpg -->
    <img class="rounded leftimage" style="width:675px; height:490px;" src="">
    <p>
      Dark forces battle to cover LEGO Universe in chaos!
    </p>
    <p>
      Will you answer the call to save imagination?
    </p>
  </div>
</template>
